$(
	function() {
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.setBackUrls();
		app.attachSvgEvents();
		app.addCoverSpan();
		app.assignProductOver();
		app.saveProductListInfo();
	}
);

var app = {
    messages : {},
	iniProdItemHeight : null,
	productAvailableQuantityUrl : null,
	optionsWereInitialized : false
};